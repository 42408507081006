<template>
    <div :class="{ 'bg-gray-800 text-white': isDarkMode, 'bg-white text-black': !isDarkMode }"
        class="w-full p-4 bg-cover bg-center rounded-lg shadow-md relative"
        :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
        <div class="relative flex flex-col sm:flex-row items-center justify-between">
            <div
                class="relative w-24 h-24 sm:w-32 sm:h-32 bg-yellow-500 rounded-full overflow-hidden border-2 border-gray-300 flex items-center justify-center">
                <img v-if="user && user.look" class="transform scale-125 translate-y-2"
                    :src="`http://www.habbo.com/habbo-imaging/avatarimage?figure=${user.look}&direction=3&head_direction=3&gesture=nor&action=null&size=l&headonly=0&img_format=gif`"
                    alt="Profile" />
            </div>
            <div class="flex-1 mt-4 sm:mt-0 sm:ml-4 text-center sm:text-left">
                <h2 class="text-xl sm:text-2xl font-semibold text-white relative">{{ user.username }}</h2>
                <div
                    class="flex flex-col sm:flex-row justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4 mt-2">
                    <div class="text-center text-white relative">
                        <span class="font-bold">{{ user.credits }}</span>
                        <div class="text-gray-300">Crédits</div>
                    </div>
                    <div class="text-center text-white relative">
                        <span class="font-bold">{{ user.pixels }}</span>
                        <div class="text-gray-300">Pixels</div>
                    </div>
                    <div class="text-center text-white relative">
                        <span class="font-bold">{{ user.points }}</span>
                        <div class="text-gray-300">Points</div>
                    </div>
                </div>
            </div>
            <div class="mt-4 sm:mt-0 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                <router-link v-if="isCurrentUser" to="/settings"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg relative text-center">Settings</router-link>
                <router-link v-if="isCurrentUser" to="/client"
                    class="px-4 py-2 bg-green-500 text-white rounded-lg relative text-center">Play Now</router-link>
            </div>
        </div>
        <div class="relative mt-4 text-center sm:text-left">
            <p class="text-gray-300">{{ user.motto }}</p>
        </div>
        <div class="relative grid grid-cols-2 sm:grid-cols-3 gap-4 mt-6">
            <div v-for="post in user.posts" :key="post.id" class="relative">
                <img :src="post.image" alt="Post Image" class="w-full h-32 sm:h-48 object-cover rounded-lg" />
                <div
                    class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                    <fa-icon :icon="['fas', 'heart']" class="text-white mr-2" />
                    <span class="text-white">{{ post.likes }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import backgroundImage from '@/assets/images/skeleton/topbg.png'
import axios from 'axios'

library.add(faHeart)

export default {
    components: {
        'fa-icon': FontAwesomeIcon
    },
    props: {
        isDarkMode: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            user: {},
            backgroundImage,
            isCurrentUser: false
        }
    },
    async created() {
        const userId = this.$route.params.userId || 'me';
        const currentUserId = localStorage.getItem('userId');
        this.isCurrentUser = userId === currentUserId || userId === 'me';
        await this.fetchUserProfile(userId);
    },
    methods: {
        async fetchUserProfile(userId) {
            try {
                const token = localStorage.getItem('token');
                const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:3000';
                const url = userId === 'me' || !userId ? `${apiUrl}/profile/me` : `${apiUrl}/profile/${userId}`;
                const response = await axios.get(url, {
                    headers: { 'x-access-token': token }
                });
                this.user = response.data;
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
